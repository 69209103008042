.slick-prev:before,
.slick-next:before
{
    color:#242752!important;
}
.slick-prev:before{
    content:'‹';
    font-size: 52px;
    font-weight: bold;
}
.slick-next:before{
    content:'›';
    font-size: 52px;
    font-weight: bold;
}
.back_membresia_home{
    background-image: url('../images/reunion_anual/CIRCULOS/circulo_azulito.png');
    background-color: #1B728B;
    background-repeat: no-repeat;
    padding: 0% 0% 0% 0%;
    background-size: 250px;
    background-position:90% 0%;

}
.back_azum_membresia{
    background-color: #022435;
    display: table!important;
    height:50px;
    border-radius: 25px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    line-height: 1;
    cursor: pointer;
}
.back_azum_membresia_select{
    background-color: transparent;
    border: 4px solid #022435;
    color: #ffffff;
    display: table!important;
    height:50px;
    border-radius: 25px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
}

.back_gris_membresia{
    background-color: #62ABBB;
    display: table!important;
    height:50px;
    border-radius: 25px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #022435;
    line-height: 1;
    cursor: pointer;
}
.back_gris_membresia_select{
    background-color: transparent;
    border: 4px solid #62ABBB;
    color: #ffffff;
    display: table!important;
    height:50px;
    border-radius: 25px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
}
.contenedor_membresias_interior {
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 3% 0%;
}
.home_titulo_membresia{
    text-align: left;
    font-size: 16px;
    margin-bottom: 0px;
    color:#ffffff;
    line-height: 1;
    padding-left: 5px;
}
.dvi{

    display: table-cell!important;
   vertical-align: middle!important;
}
.textos_titulos_seccion{
    font-size: 52px;
    line-height: 30px;
    font-weight: bold;
    color: #ffffff;
    padding-top: 3%;
    text-align: left;
    font-family: 'Montserrat';
}
.contenedor_targeta_membresia{
    background-color: rgba(255, 255, 255, 0.8);
    margin: 1% 0%;
    padding: 1%;
}
.nombre_membresia{
    color: #000000;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}
.link_membresia{
    color: #10447d;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}
.regresar_membresia{
    color: #101010;
    text-align: center;
    font-size: 22px;
}
.titulo_tipo_membresia{
    color: #10447d;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin: 0px;
}
.titulo_membresia{
    color: #1d2143;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
}
.padding_btns{
    padding-top: 0%;
}
@media screen and (max-width:767px){
    .textos_titulos_seccion{
        font-size: 22px;
        line-height: 10px;
    }
    .btn_azul_membresia{
        font-size: 14px;
    }
    .titulo_tipo_membresia{
        font-size: 16px;
    }
    .regresar_membresia{
        font-size: 12px;
    }
    .titulo_membresia{
        font-size: 22px;
    }
    .margin_chico{
        margin: 10px 0px;
    }
    .back_membresia_home{
        background-image: url('../images/reunion_anual/CIRCULOS/circulo_azulito.png');
        background-size: 80px;
        background-position:90% 0%;
    
    }
    .back_azum_membresia, .back_gris_membresia_select, .back_azum_membresia_select, .back_gris_membresia{
        display: table!important;
        height:50px;
        border-radius: 25px;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        line-height: 1;
        cursor: pointer;
    }
    .margin_chico{
        margin: 5px 0px;
    }
}