.contenedor_inical{
    position: relative;
}
.contenedor_banner_menu{
    padding-top: 120px;
}
.img_banner_inicial{
    width: 100%;
    position: relative;
}

.boton_registro_inicial{
    width: 150px;
    position: absolute;
    z-index: 99;
    bottom: 20%;
    right: 10%;
}

.boton_registro_calendar{
    width: 150px;
    position: absolute;
    z-index: 99;
    bottom: 20%;
    right: 30%;
}

.dudas_btn{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0px;
    text-align: left;
}
.costo_patrocinio_hoome{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0px;
    text-align: left;
}
.btn_patrocinio_hoome{
    font-size: 20px;
    background-color: #62ABBB;
    color: #ffffff;
    border-radius: 20px;
    padding: 7px 15px;
}
.datos_back{
    background-image: url('../images/segunda_reunion/DATOS/datos_fondo_ampip.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100%;
}

@media screen and (max-width: 767px) {
    .boton_registro_inicial{
        width: 100px;
        position: absolute;
        z-index: 99;
        }

        .boton_registro_calendar{
            width: 100px;
            position: absolute;
            z-index: 99;
            }
            .contenedor_banner_menu{
                padding-top: 50px;
            }
            .costo_patrocinio_hoome{
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 0px;
                text-align: left;
            }
            .btn_patrocinio_hoome{
                font-size: 14px;
                background-color: #62ABBB;
                color: #ffffff;
                border-radius: 20px;
                padding: 7px 15px;
            }
}


@media screen and (max-width: 565px) {
    .boton_registro_inicial{
        width: 60px;
        position: absolute;
        z-index: 99;
        bottom: 10%;
        }

        .boton_registro_calendar{
            width: 60px;
            position: absolute;
            z-index: 99;
            bottom: 10%;
            }
            .dudas_btn{
                font-size: 14px;
            }
}