.patro_plata{
    padding: 5px 40px;
    font-size: 26px;
    width: 100px;
}

.patro_oro{
    padding: 5px 60px;
    font-size: 26px;
    width: 100px;
}