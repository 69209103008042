.estilo_campos{
    color:white;
}
.back_registro{
    background-image: url('../images/casa/back_registro.jpg');
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 3%;
}
.desc_registro{
    font-size: 18px;
    text-align: center;
    line-height: 1.2;
}
.ant-input{
    background-color: #f1f2f2!important;
    border: 0px solid #d9d9d9!important;

}
.ant-input-affix-wrapper{
    background-color: #f1f2f2!important;
    border: 0px solid #d9d9d9!important;
}

.nom-label{
    color:#606060!important;
    font-size: 18px!important;
}
.nom-label-cel{
    color:#538ae4!important;
    font-size: 18px!important;
}
.num-men{
    background-color: #538ae4;
    color:#ffffff;
    font-size: 18px;
}
.ant-form-item-required{
}
.agregar_persona{
    background-color: #62abbb;
    color:#ffffff;
    text-align: center;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 18px;
    cursor: pointer;
}
.quitar_persona{
    background-color: #e16948;
    color:#ffffff;
    text-align: center;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 18px;
    cursor: pointer;
}
@media only screen and (max-width: 500px) {
    .nom-label, .nom-label-cel, .num-men{
        font-size: 12px!important;
    }
    .ant-form-item-required{
        font-size: 12px!important;
    }
    
}
@media only screen and (max-width: 970px) {
    .back_registro{
        background-image: url('../images/casa/movil/back_registro_movil.jpg');
        background-position:center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        padding: 3%;
    }
    .desc_registro{
        font-size: 14px;
    }
}
.transmision{
    width: 50%;
}

.select_telefono{
    width:100px;
}

.boton_recibir_codigo{
    color: #ffffff !important;
    background-color:#4067b0 !important;
    border: 3px solid #4067b0!important;
    font-weight: bold;
    cursor: pointer;
}

.boton_registrate{
    color: #538ae4 !important;
}

.boton_sin_login{
    background-color: #083280 !important;
    color: white !important;
}
.selector_radio_opcion{
    color:black;
}

.selector_radio_opcion:hover{
    color:#538ae4 !important;
}

.selector_radio_opcion:focus{
    color:#538ae4 !important;
}

