.back_hospedaje{
    padding: 0% 0% 3% 0%;
    background-attachment: fixed;
    background-color: #e2e2e2;
    background-size: 100%;
    background-position: center;
    background-image: url('../images/reunion_anual/HOSPEDAJE/back_hospedaje.jpg');
  }
.text_safi{
    font-size: 20px;
    font-weight: bold;
}
.text_safi_ubicacion{
    font-size: 10px;
}
.circulo_hospedaje{
    background-image: url('../images/reunion_anual/HOSPEDAJE/circulo_azulito.png');
    background-size: 300px;
    background-repeat: no-repeat;
}
.back_dispar_2{
    background-color: rgba(52, 51, 51, 0.2);
}
.texto_hospedaje{
    font-size: 16px;
    text-align: left;
    padding: 10px 20px;
}
.texto_hospedaje_2{
    font-size: 15px;
    text-align: left;
    padding: 10px 20px;
}
.texto_hospedaje_3{
    font-size: 15px;
    text-align: left;
    padding: 10px 0px;
}
@media screen and (max-width:767px){
    .circulo_hospedaje{
        background-size: 100px;
        background-repeat: no-repeat;
    }
    .text_safi{
        font-size: 12px;
        font-weight: bold;
    }
    .text_safi_ubicacion{
        font-size: 10px;
    }
    .texto_hospedaje{
        font-size: 14px;
    }
    .texto_hospedaje_2{
        font-size: 13px;
    }
    .texto_hospedaje_3{
        font-size: 13px;
    }
    
}