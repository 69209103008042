.texto_costos{
    font-size: 16px;
    text-align: left;
}
.contenedor_costo{
    background-color: rgba(0,0,0,0.2);
    border-radius: 20px;
    text-align: left;
    padding: 25px 25px;
}
.datos_sub{
    font-size: 28px;
    text-align: center;
}
.transferen_text{
    font-size: 22px;
    text-decoration: underline;
}
.block_titulo{
    font-size: 19px;
    font-weight: bold;
}
.block_info{
    font-size: 15px;
    font-weight: bold;
}
.texto_final_costos{
    font-size: 16px;
    text-align: center;
}
.back_costos_sec{
    background-image: url('../images/reunion_anual/COSTOS/circulo_beige.png');
  background-repeat: no-repeat;
  background-size: 250px;
  background-position:90% 0%;
}
@media screen and (max-width:767px){
    .back_costos_sec{
        background-image: url('../images/reunion_anual/COSTOS/circulo_beige.png');
      background-repeat: no-repeat;
      background-size: 90px;
      background-position:90% 0%;
    }
    .texto_costos{
        font-size: 14px;
        text-align: left;
    }
    .datos_sub{
        font-size: 22px;
        text-align: center;
    }
    .transferen_text{
        font-size: 14px;
        text-decoration: underline;
    }
    .block_titulo{
        font-size: 15px;
        font-weight: bold;
    }
    .block_info{
        font-size: 13px;
        font-weight: bold;
    }
    .texto_final_costos{
        font-size: 14px;
        text-align: center;
    }
}