
.footer{
  border-top: 10px solid #bababb;
}
.pie-link{
  font-size:26px;
  color:#ffffff;
  text-align: left;
  margin: 0px!important;
}
.pie-peq{
  font-size:10px;
  color:#ffffff;
  text-align: justify;
  line-height: 1.4;
}
.text-blanco{
  color: #ffffff;
  text-align: left;
}
.text-azul{
  color: #0d3159;
  text-align: left;
}
.tituLogos{
  border-bottom: 4px solid #000000;
  font-size: 2em;
  text-align: left;
  color: #000000;
  padding-top: 50px;
}
.redes{
  width: 50px;
}
.redes_2{
  width: 40px;
}
.contendor_redes{
  text-align: center;
}
.link_sitio{
  padding: 5px 7px;
  font-size: 25px;
  color:#ffffff;
  font-weight: bold;
  text-align: left;
}
.link-footer{
  color: #000000;
  text-decoration: none;
}

.has_footer{
  color:#ffffff;
  font-size: 30px;
  text-align: center;
}
.row_contendor{
  margin: 15px 0px;
}
.titulo_patrocindor_transmision{
  text-align: center;
  font-weight: bold;
  font-size: 35px;
  line-height: 28px;
  color: #083280;
}

.titulo_patrocindor{
  text-align: center;
  font-weight: bold;
  font-size: 45px;
  line-height: 25px;
  color: #083280;
}
.contenedor_img_patrocinador{
  text-align: left;
}
.logo_patrocinador{
  width: 70%;
}
.contenedor_infop{
  padding: 3%;
}
.decripcion_patrocinador{
  color:#ffffff;
  font-size: 18px;
  text-align: justify;
}
.btn_patrocinador{
  width: 60%;
}

.texto_contacto_cecilia{
  font-size: 14px;
}
.btn_regresar{
  color: #414042;
}

.btn_regresar:hover{
  color: #4067b0;
}
@media screen and (max-width:767px){
  .titulo_patrocindor{
    font-size: 40px;
  }
  .contenedor_img_patrocinador{
    text-align: center;
  }
  .contendor_redes{
    text-align: center;
    margin-bottom: 10px;
  }
  .has_footer{
    color:#ffffff;
    font-size: 18px;
  }
  .link_sitio{
    font-size: 16px;
  }

  .redes{
    width: 25px;
  }
  .redes_2{
    width: 20px;
  }

  .decripcion_patrocinador{
    color:#ffffff;
    font-size: 12px;
    text-align: justify;
  }

  .texto_contacto_cecilia{
    font-size: 10px;
  }
}
@media only screen and (max-width: 500px) {
  .titulo-registrar{
  font-size: 24px;
  }
  
}