.back_menu{
  background-color:rgba(0,0,0,0.1);
}
.back_menu_scroll{
  background-color:rgba(0,0,0,0.6);
}
.back_menutop_membresia{
  background-color:#1d2143;
}

/* For demo */

.titles{
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 12px;
}

.titles_registro{
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 20px;
}
.linea_cargos{
  line-height: 1.2;
}

.img_logo_scania_{
  width:40%;
}
.contenedor_eventos{
  padding: 2% 0%;
  background-color: #ffffff;
}
.div_home_blanco{
  background-color: #4067b0;
  padding: 10px 20px;
  box-shadow: 1px 1px 2px 1px #ffffff;
  border-radius: 12px;
  width: 80%;
  margin-left: 10%;
}
.div_home_aqua{
  background-color: #00b7ba;
  padding: 10px 20px;
  box-shadow: 1px 1px 2px 1px #ffffff;
  border-radius: 12px;
  width: 80%;
  margin-left: 10%;
}
.texto_home_eventos{
  font-size: 20px;
  padding: 0px;
  margin:0px;
}
.texto_home_eventos_fecha{
  font-size: 24px;
  padding: 0px;
  margin:0px;
}
.div_home_azul{
    background-color: #1b69ef;
    padding: 10px 20px;
  box-shadow: 1px 1px 2px 1px #9f9f9f;
  border-radius: 12px;
}
.textos_home{
  font-size: 16px;
  color: #ffffff;
  text-align: justify;
  margin:0px;
}
.textos_home_2{
  font-size: 22px;
  margin:0px;
  text-align: left;
  margin: 0px;
}
.text_banner{
  text-align: center;
  color: #ffffff;
  font-size: 22px;
  font-weight: bold;
}
.img_banner_icono{
  width: 40px;
}
.etiqueta_banner{
  background-color: #0ac2c2;
  padding: 2px 5px;
  border-radius: 25px;
}
.datos_texto{
  font-size: 24px;
  color:#ffffff;
  margin: 0px;
  font-weight: bold;
  line-height: 1;
}
.datos_texto_chico{
  font-size: 14px;
}
@media screen and (max-width:767px){
  .img_logo_scania_{
    width:75%;
  }
  .textos_home{
    font-size: 12px;
  }
  .textos_home_2{
    font-size: 16px;
  }
  .datos_texto{
    font-size: 10px;
  }
  .datos_texto_chico{
    font-size: 8px;
  }
}

.titles-redes{
  text-decoration: none;
  color: #808285;
  font-size: 12px;
}


.espacio{
  height: 370px;
}
.titulo{
  color: #ffffff;
  font-size: 45px;
  line-height: 1.4;
  font-weight: bold;
}
.fecha-top{
  color: #f4b414;
  font-size: 40px;
  line-height: 1;
  font-family:  'Bebas Neue';
}
.mensaje-contador{
  font-size: 40px;
  font-weight: bold;
  padding: 0px!important;
  color: #0ac2c2;
  line-height: 1;
}
.mensaje-clic{
  font-size: 20px;
  color: #0ac2c2;
}
.contenedor_contador{

}
figure.containImageLiveANTP {
  width: 100%;
  text-align: center;
  overflow: hidden;
  /*hide bounds of image*/
  margin: 0;
  /*reset margin of figure tag*/
}


figure.containImageLiveANTP img {
  display: block;
  /*remove inline-block spaces*/
  width: 100%;
  /*make image streatch*/
  margin: -10% 0;
}

@media screen and (max-width: 575px) {
  .contenedor_contador{
    margin-top:25px !important;
  }
  .text_banner{
    font-size: 12px!important;
    line-height: 1;
  }
  .img_banner_icono{
    width: 20px;
  }
}



.numero{
  color: #ffffff;
  font-size: 50px;
  font-weight: bold;
}
.fecha{
  color: #ffffff;
  font-size: 14px;
}
.div_contador{
  /*background-image: linear-gradient(#10447d, #0ac2c2);*/
  padding: 10px 0px;
}
.contador{
  line-height: 1;
  margin-bottom: 0.6em;
}
.contadorf{
  line-height: 1;
}
.subtitle{
  color: #ffffff;
  font-size: 24px;
  line-height: 1;
  margin-top: 3%;
}
.ponentes1{
  background-image: url('../images/temp/img1200x600.png');
  border-image-repeat: no-repeat;
  height: 550px;
  background-size: cover;
}
.ponentes2{
  background-image: url('../images/temp/img1200x600.png');
  border-image-repeat: no-repeat;
  height: 550px;
  background-size: cover;
}
.ponente{
  width: 100%;
}
.programa{
  background-image: url('../images/temp/img1200x600.png');
  border-image-repeat: no-repeat;
  background-size: cover;
}
.divPrograma{
  background-color: #ffffff;
  text-align: left;
  line-height: 1;
}
.tituPrograma{
  color:#ffffff;
  text-align: center;
  margin: 3% 0%;
  font-size: 3em;
}
.visualizar{
  border-bottom: 1px solid #000000;
}
.info-hora{
  color:#000000;
  font-size: 1.3em;
  font-family: 'Economica', sans-serif;
  font-weight: bold;
}
.info-anio{
  color:#eb4b1c;
  font-size: 2em;
}
.info-fecha{
  color:rgb(25,48,122);
  font-size: 0.9em;
}
.info-nombre{
  color:#5c6069;
  font-size: 21px;

}
/*font-family: 'Montserrat', sans-serif;*/
.info-descripcion{
  color:#01c7e2;
  font-size: 0.8em;
  /*font-family: 'Montserrat', sans-serif;*/
}
.info-video{
  color:#000000;
}
.info-horario{
  margin: 15px;
}
.entradas{
  background-image: url('../images/temp/img1200x600.png');
  border-image-repeat: no-repeat;
  background-size: cover;
}
.divEntradas{
  text-align: right;
  line-height: 1;
}
.tituEntradas{
  color:#eb4b1c;
  margin: 3% 0% 0% 0%;
  font-size: 3em;
}
.textEntradas{
  color:#000000;
  font-size: 1.5em;
}
.tituLogos{
  border-bottom: 4px solid #000000;
  font-size: 3em;
  text-align: left;
  color: #000000;
}

.btn-registrar{
  width: 200px;
}

@media only screen and (max-width: 2700px) {
  .principal{
    height: 1200px!important;
  }
  .espacio{
    height: 700px;
  }
  .numero{
    font-size: 90px;
  }
  .btn-registrar{
    width: 500px;
  }

}
@media only screen and (max-width: 1700px) {
  .principal{
    height: 750px!important;
  }
  .espacio{
    height: 440px;
  }
  .numero{
    font-size: 64px;
  }
  .btn-registrar{
    width: 250px;
  }
  .web-pasado{
    width: 50%;
  }
}

@media only screen and (max-width: 1600px) {
  .principal{
    height: 700px!important;
  }
  .espacio{
    height: 400px;
  }
  .numero{
    font-size: 44px;
  }
  .btn-registrar{
    width: 250px;
  }
  .web-pasado{
    width: 50%;
  }
}

@media only screen and (max-width: 1200px) {
  .principal{
    height: 500px!important;
  }
  .espacio{
    height: 280px;
  }
  .numero{
    font-size: 40px;
  }
  .btn-registrar{
    width: 200px;
  }
}
@media only screen and (max-width: 1300px) {
  .principal{
    height: 570px!important;
  }
  .espacio{
    height: 350px;
  }
  .numero{
    font-size: 40px;
  }
  .btn-registrar{
    width: 200px;
  }
}
@media only screen and (max-width: 1100px) {

  .espacio{
    height: 300px;
  }
  .numero{
    font-size: 40px;
  }
  .btn-registrar{
    width: 200px;
  }
  .mensaje-contador{
    font-size: 25px;
  }
  .titles{
    font-size: 12px;
    padding: 10px 8px;
  }
}
@media only screen and (max-width: 990px) {
  .principal{
    height: 510px!important;
  }
  .espacio{
    height: 260px;
  }
}
@media only screen and (max-width: 890px) {
  .principal{
    height: 380px!important;
  }
  .espacio{
    height: 210px;
  }
  .numero{
    font-size: 30px;
  }
  .btn-registrar{
    width: 150px;
  }
  .info-hora{
    font-size: 1.3em;
  }
  .info-fecha{
    font-size: 1.3em;
  }
  .info-nombre{
    font-size: 1.1em;
  }
  .info-descripcion{
    font-size: 0.8em;
  }
  .mensaje-contador{
    font-size: 20px;
  }
}
@media only screen and (max-width: 700px) {
  .principal{
    height: 320px!important;
  }
  .espacio{
    height: 200px;
  }
  .fecha{
    font-size: 14px;
  }
  .numero{
    font-size: 22px;
  }
  .btn-registrar{
    width: 100px;
  }
  .info-hora{
    font-size: 1.1em;
  }
  .info-fecha{
    font-size: 1.1em;
  }
  .info-nombre{
    font-size: 0.9em;
  }
  .info-descripcion{
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 550px) {

  .espacio{
    height: 150px;
  }
  .numero{
    font-size: 18px;
  }
  .sec-titulo{
  padding-top: 30px;
  }
}
@media only screen and (max-width: 500px) {
  .principal{
    height: 230px!important;
  }
  .espacio{
    height: 110px;
  }
  .numero{
    font-size: 20px;
  }
  .web-pasado{
    width: 100%;
  }
  .info-completa{
    margin-bottom: 0px!important;
    padding-top: 0%;
  }
  .mensaje-contador{
    font-size: 12px;
  }
  .mensaje-clic{
    font-size: 12px;
  }
}
@media only screen and (max-width: 400px) {
  .principal{
    height: 150px!important;
  }
  .espacio{
    height: 90px;
  }
  .numero{
    font-size: 16px;
  }
  .fecha{
    font-size: 6px;
  }
  .contador{
    margin-bottom: 0.2em;
  }
}

.seccion{
  font-family: 'Amie-bold' !important;
  color:#ffffff;
  font-weight: bold;

}

.seccion_sel{
  font-family: 'Amie-bold' !important;
  padding: 0px;
  color:#0ac2c2;
  font-weight: bold !important;
}

.seccion-redes{
  padding: 15px;
  border-right: 2 solid #808285;
}
.subir{
  margin-bottom: 20px;
}
.titulo-ponentes{
  color: #083280;
  font-size: 3em;
  text-align: left;
  margin: 20px 0px!important;
  font-weight: bold;
}
.texto_experiencia{
  color: #083280;
  font-size: 2.2em;
  text-align: left;
  font-weight: bold;
  margin: 0px;
}

@media screen and (max-width:575px){
  .texto_experiencia{
    color: #083280;
    font-size: 1.9em;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    margin: 0px;
  }
}

.parrafo_experiecia{
  color:#4f4f4f;
  font-size: 18px;
  text-align: justify;
  line-height: 1.2;
}
.contenedor_experiencia{
  padding: 2%;
}

.img_dia_agenda{
  width: 100%;
  cursor: pointer;
}
/* VISTA DE PONENTES */
.head-ponentes{
  background-color: #538ae4;

}
.head-ponentes-text{
  color:#ffffff;
  font-size: 51px;
  margin: 15px 0px!important;
}
.nombre-ponente{
  color:#01356f;
  font-size: 50px;

}
.titu-ponente{
  color:#01356f;
  font-size: 30px;
}

.desc-ponente{
  color:#01356f;
  font-size: 14px;
}

.seccion_movil{
  color: #083280 !important;
}

.seccion_movil_registro{
  color: white !important;
}
