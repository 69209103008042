p{
  margin-bottom: 0px!important;
}

.back_titulo_agenda{
  background-image: url('../images/reunion_anual/puntos_naranja-.png');
  background-repeat: no-repeat;
  /*background-attachment: fixed;*/
  background-size: 250px;
  background-position:60px 0px;
}
.espacio_dias{
  padding: 0px 5px;
}
.text_agenda_dia{
  padding:8px 16px;
  font-size:18px;
}
.circulo_agenda{
  color: #d5d6d6;
  font-size: 2em;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 30px;
}
.back_dispar{
  background-color: rgba(255, 255, 255, 0.039);
}
.back_dispar2{
  background-color: rgba(255, 255, 255, 0.1);
  background-image: url('../images/reunion_anual/back_golf.jpg');
  background-repeat: none;
  background-size: cover;
}
.borde_agenda{
  padding: 10px 20px;
  border-bottom: 1px solid #d5d6d6;
}
.circulo_agenda::before{
  content: '';
  position: absolute;
  border-color: #00b7ba;
  border-style: solid;
  border-radius: 50%;
  height: 0.8em;
  top: 40px;
  left: -0.43em;
  margin-top: -1em;
  width: 0.8em;
  background-color: #00b7ba;
}
.hora_agenda{
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
}
.titulo_agenda{
  font-size: 17px;
  color: #ffffff;
  text-align: left;
  font-weight:bold;
}
.text_p{
  font-size: 14px;
}
.text_d{
  font-size: 12px;
  font-weight: lighter;
}
.sub_agenda{
  font-size: 15px;
  color: #0ac2c2;
  text-align: left;
  font-weight: normal;
}
.desc_agenda{
  font-size: 15px;
  color: #ffffff;
  text-align: left;
}
.nombre_agenda{
  font-size: 17px;
  color: #ffffff;
  text-align: left;
  font-weight:bold;
}
.cargo_nombre{
  font-size: 15px;
  color: #0ac2c2;
  text-align: left;
}
.linea_agenda_cero{
  padding-bottom: 0px !important;
  margin-bottom: 0px;
}


.linea_agenda{
  border-bottom: 2px solid #d5d6d6;
  padding-bottom: 5px;
}

.img_registrar_master_class{
  width: 400px;
}

.fecha_text_agenda{
  font-size: 22px;
}
.tex_agenda_dia{
  line-height: 1;
  text-align: center;
  padding: 10px 15px;
  font-size: 26px;
}
.txt_cabos_agenda{
  font-size: 18px;
}
.btn_naranja{
  padding: 10px 20px;
  font-size: 17px;
  border-radius: 20px;
}
.btn_naranja_2{
  padding: 10px 20px;
  font-size: 17px;
  border-radius: 20px;
}
.btn_hueso{
  padding: 10px 20px;
  font-size: 17px;
  border-radius: 20px;
  margin: 0px 5px;
  color:#1d2143!important;
  background-color:#EEEAE1;
}
.btn_hueso:hover{
  color:#1d2143!important;
  text-decoration: none;
}
@media screen and (max-width:767px){
  .hora_agenda{
    font-size: 16px;
    line-height: 12px;
  }
  .titulo_agenda{
    font-size: 16px;
  }
  .otro_agenda{
    font-size: 10px;
  }
  .desc_agenda_desc{
    font-size: 12px;
  }

  .desc_agenda{
    font-size: 12px;
  }
  .circulo_agenda{
    padding-left: 20px;
  }

  .img_registrar_master_class{
    width: 150px;
  }

  .text_dias{
    font-size: 18px;
  }

  .circulo_agenda::before{
    height: 0.5em;
    top: 26px;
    left: -0.25em;
    margin-top: -1em;
    width: 0.5em;
    background-color: #1b69ef;
  }
  .text_agenda_dia{
    font-size:14px;
  }
  .fecha_text_agenda{
    font-size: 12px;
  }
  .tex_agenda_dia{
    padding: 5px 10px;
    font-size: 18px;
  }
  .back_titulo_agenda{
    background-size: 80px;
    background-position:10px 0px;
  }
  .txt_cabos_agenda{
    font-size: 11px;
  }
  .btn_naranja_2, .btn_naranja, .btn_hueso{
    margin: 0% 0% 0% 0%;
    font-size: 14px;
  }
  
}